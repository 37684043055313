<template>
  <structure-auth>
    <figure>
      <hs-logo height="25" />
    </figure>
    <hs-loading v-if="isLoading" />
    <template v-else>
      <hs-form @submit="create">
        <hs-group>
          <label>{{ $t('sparkfunnels.invite.create.form.first_name') }}</label>
          <hs-input
            id="firstName"
            type="text"
            @blur="$v.form.firstName.$touch()"
            v-model="form.firstName"
            :state="!$v.form.firstName.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.firstName.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.firstName.required">
                {{ $t('sparkfunnels.invite.create.form.validation.required') }}
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <hs-group>
          <label>{{ $t('sparkfunnels.invite.create.form.last_name') }}</label>
          <hs-input
            id="lastName"
            type="text"
            @blur="$v.form.lastName.$touch()"
            v-model="form.lastName"
            :state="!$v.form.lastName.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.lastName.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.lastName.required">
                {{ $t('sparkfunnels.invite.create.form.validation.required') }}
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <hs-group>
          <label>{{ $t('sparkfunnels.invite.create.form.email') }}</label>
          <hs-input
            id="email"
            type="email"
            @blur="$v.form.email.$touch()"
            v-model="form.email"
            :state="!$v.form.email.$error ? null : false"
          >
            <template slot="feedback" v-if="$v.form.email.$error">
              <hs-form-invalid-feedback :state="false">
                <span v-if="!$v.form.email.required">
                  {{ $t('sparkfunnels.invite.create.form.validation.required') }}
                </span>
                <span v-if="!$v.form.email.email">
                  {{ $t('sparkfunnels.invite.create.form.validation.email') }}
                </span>
              </hs-form-invalid-feedback>
            </template>
          </hs-input>
        </hs-group>

        <hs-group>
          <label>{{ $t('sparkfunnels.invite.create.form.roles') }}</label>
          <hs-multiselect
            id="roles"
            v-model="form.role"
            track-by="name"
            label="name"
            :placeholder="$t('sparkfunnels.invite.create.form.select.placeholder')"
            :options="roles"
            :searchable="false"
            :allow-empty="false"
            :show-labels="false"
          />
          <template v-if="!$v.form.role.$error">
            <hs-form-invalid-feedback :state="false" v-if="!$v.form.role.required">
              {{ $t('sparkfunnels.invite.create.form.validation.required') }}
            </hs-form-invalid-feedback>
          </template>
        </hs-group>

        <hs-button variant="primary" type="submit" :disabled="$v.form.$invalid">{{
          $t('sparkfunnels.invite.create.form.to_invite')
        }}</hs-button>
      </hs-form>
    </template>
  </structure-auth>
</template>

<script>
import { hsForm, hsLoading, hsLogo } from '@/components';
import { mapState, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import StructureAuth from '@/auth/components/Auth';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    hsForm,
    hsLoading,
    hsLogo,
    StructureAuth,
  },
  computed: {
    ...mapState('auth', {
      roles: state => state.roles,
    }),
  },
  data() {
    return {
      isLoading: false,
      form: {
        firstName: null,
        lastName: null,
        email: null,
        role: null,
      },
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      role: {
        required,
      },
    },
  },
  methods: {
    ...mapActions('auth', ['createInvite', 'getRoles']),
    create() {
      const params = { ...this.form, roleId: this.form.role.id };
      this.isLoading = true;
      this.createInvite(params)
        .then(
          () => {
            this.resetForm();
          },
          err => {
            if (err.response.status === 422) {
              ToastHelper.dangerMessage(this.$t('sparkfunnels.invite.create.form.error.422'));
              return;
            }
            ToastHelper.dangerMessage(this.$t('sparkfunnels.invite.create.form.error.default'));
          }
        )
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.invite.create.form.error.default')))
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetForm() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },
  },
  created() {
    if (!this.roles.length) {
      this.isLoading = true;
      this.getRoles().finally(() => {
        this.isLoading = false;
      });
    }
  },
};
</script>
